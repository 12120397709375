import React, { useEffect, useMemo, useState } from 'react';
import {
  ListItem,
  Typography,
  Box,
  Grid,
  IconButton,
  Button,
  Tooltip,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import TodayIcon from '@material-ui/icons/Today';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import iconWarehouse from '../../../assets/img/icon_warehouse.png';
import CancelIcon from '@material-ui/icons/Cancel';

import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import styles from './styles';
import {
  B2BOrder,
  B2BOrderCargoPlace,
  B2BOrderProduct,
  B2BShipmentMethodTypeEnum,
  B2BWrapTypeEnum,
  UserRoles,
  WarehouseB2BStatus,
  useCancelB2BOrderMutation,
  useCompleteB2BOrderMutation,
  useGetB2BOrderByIdQuery,
  useGetCurrentUserQuery,
} from '../../../generated/graphql';
import TableList from '../../../components/TableList/TableList';
import { productsHeadCells } from './options';
import { TRoutes } from '../../../utils/helpers';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { cargoPlacesHeadCells } from '../B2bShipmentPackaging/options';
import { TFunction } from 'i18next';
import {
  B2B_SHIPMENT_METHOD_BY_METHOD,
  COLORS,
  DATE_FORMAT,
  TIME_FORMAT,
  WAREHOUSE_B2B_STATUS_BY_STATUS,
} from '../../../utils/constants';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import { GET_B2B_ORDER_BY_ID_QUERY } from '../../../GraphQL/queries/getB2BOrderById';
import theme from '../../../assets/theme';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GET_B2B_ORDER_LIST_QUERY } from '../../../GraphQL/queries/getB2BOrderList';
import CancelWarehouseShipmentOrderModal from '../../../components/Modals/CancelWarehouseShipmentOrderModal';
import { TSetStateBoolean } from '../../../utils/types';
import B2bShipmentViewCargoPlaceModal, {
  B2BOrderProductWithCargoQuantity,
} from '../../../components/Modals/B2bShipmentsModals/B2bShipmentViewCargoPlaceModal';

const createProductsData = (tableRowData: B2BOrderProduct) => {
  const relatedProduct = tableRowData.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    quantity: tableRowData?.expectedQuantity,
  };
};

const createData = (
  tableRowData: B2BOrderCargoPlace,
  t: TFunction<'translation', undefined>,
  id: number,
  setIsOpenModal: TSetStateBoolean,
  setCargoPlace: React.Dispatch<
    React.SetStateAction<B2BOrderCargoPlace | null>
  >,
  index: number,
  setCargoPlaceNumber: React.Dispatch<React.SetStateAction<number | null>>,
  type: B2BWrapTypeEnum,
) => {
  return {
    type,
    uniqSkuCount: tableRowData.uniqSkuCount,
    productQty: tableRowData?.productQty,
    dimensions: `${tableRowData?.width}x${tableRowData?.length}x${
      tableRowData?.height
    } ${t('app.cm')}`,
    weight: `${tableRowData?.weight} ${t('app.kg')}`,
    barcode: tableRowData.externalBarcodeUrl ? (
      <BoxCentered>
        <Link
          to={`/api/getAttachment/${tableRowData.externalBarcodeUrl}`}
          target='_blank'
        >
          {tableRowData.externalBarcodeUrl.slice(0, -4)}
        </Link>
      </BoxCentered>
    ) : (
      t('app.dontDownloaded')
    ),
    viewCargoPlace: (
      <Link
        to='#'
        onClick={() => {
          setIsOpenModal(true);
          setCargoPlace(tableRowData);
          setCargoPlaceNumber(index);
        }}
      >
        {t('app.view')}
      </Link>
    ),
  };
};

const createCargoProductData = (
  tableRowData: B2BOrderProductWithCargoQuantity,
) => {
  console.log('tableRowData', tableRowData);

  const relatedProduct = tableRowData.relatedProduct;
  const titleId = relatedProduct?.barcodeId?.split('.')[0];
  return {
    id: tableRowData?.productId?.toString(),
    titleId,
    productSku: relatedProduct?.sku,
    productName: relatedProduct?.name,
    b2bCargoProductsQuantity: tableRowData.b2bCargoProductsQuantity,
  };
};

const B2bShipmentDetails = () => {
  const { t } = useTranslation();
  const classes = styles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [order, setOrder] = useState<B2BOrder | null>(null);
  const history = useHistory();
  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);

  const [cargoPlaceProducts, setCargoPlaceProducts] = useState<
    B2BOrderProductWithCargoQuantity[] | null
  >(null);
  const [isOpenViewCargoPlaceModal, setisOpenViewCargoPlaceModal] =
    useState(false);
  const [cargoPlace, setCargoPlace] = useState<B2BOrderCargoPlace | null>(null);
  const [cargoPlaceNumber, setCargoPlaceNumber] = useState<number | null>(null);

  console.log('cargoPlace', cargoPlace);

  const { data, loading } = useGetB2BOrderByIdQuery({
    variables: {
      getB2BOrderByIdId: +id,
    },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [
    completeB2BOrderMutation,
    { loading: completeB2BOrderMutationLoading },
  ] = useCompleteB2BOrderMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [cancelB2BOrderMutation, { loading: cancelB2BOrderMutationLoading }] =
    useCancelB2BOrderMutation();

  useEffect(() => {
    if (data) {
      setOrder(data?.getB2BOrderById as B2BOrder);
    }
  }, [data]);

  useEffect(() => {
    if (cargoPlace && order) {
      setCargoPlaceProducts(
        cargoPlace?.productsItems?.map((product) => {
          const selectedProduct = order?.products?.find(
            (item) => item?.productId === product?.productId,
          );
          return {
            ...selectedProduct,
            b2bCargoProductsQuantity: product?.quantity,
          };
        }) as B2BOrderProductWithCargoQuantity[],
      );
    }
    //eslint-disable-next-line
  }, [cargoPlace, order]);

  const handleComplete = () => {
    completeB2BOrderMutation({
      variables: { orderId: Number(id) },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.b2bShipmentOrderSuccessfullyCompleted'), {
            variant: 'success',
          });
          history.push(TRoutes.B2B_ORDERS);
        }
      },
      refetchQueries: [GET_B2B_ORDER_BY_ID_QUERY],
    });
  };

  const cancelShipmentOrderHandler = () => {
    cancelB2BOrderMutation({
      variables: { orderId: Number(id) },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.success'), { variant: 'success' });
          history.push(TRoutes.B2B_ORDERS);
          setIsOpenConfirmCancel(false);
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_ORDER_LIST_QUERY],
    });
  };

  const rowsProducts =
    order?.products?.map((tableRowData) =>
      createProductsData(tableRowData as B2BOrderProduct),
    ) || [];

  const rows = useMemo(
    () =>
      order?.cargoPlaces?.map((tableRowData, index) =>
        createData(
          tableRowData as B2BOrderCargoPlace,
          t,
          Number(id),
          setisOpenViewCargoPlaceModal,
          setCargoPlace,
          index,
          setCargoPlaceNumber,
          order.wrapType as B2BWrapTypeEnum,
        ),
      ) || [],
    // eslint-disable-next-line
    [order],
  );

  const formattedDate = moment(Number(order?.createdAt)).format(DATE_FORMAT);

  const formattedSceduledDate = new Date(
    order?.shipmentInfo?.scheduledDate as string,
  ).toLocaleDateString();

  const formattedSceduledTime = moment(
    order?.shipmentInfo?.scheduledTime,
  ).format(TIME_FORMAT);

  const shipmentIdTitle = order?.barcodeId?.split('.')[0];

  const totalCost = order?.servicesWithCost?.reduce((acc, service) => {
    return acc + Number(service?.costInUSD) * Number(service?.qty);
  }, 0);

  const isSuperAdmin =
    currentUserData?.currentUser?.role === UserRoles.SuperAdmin;

  console.log('cargoPlaceProducts', cargoPlaceProducts);

  const rowsCargoProducts =
    cargoPlaceProducts?.map((tableRowData) =>
      createCargoProductData(tableRowData as B2BOrderProductWithCargoQuantity),
    ) || [];

  return (
    <RequestHandler loading={loading}>
      <>
        <Box mb={4}></Box>
        <Typography variant='h2' align='center' className={classes.mainTitle}>
          {t('app.viewOrder')}
        </Typography>
        <ListItem>
          <Typography variant='h3'>
            {t('app.orderNumber')}: {shipmentIdTitle}
          </Typography>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.creationDate')}: ${formattedDate}`}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.orderClient')}: `}
          <Link
            to={`${
              TRoutes.PAYMENT_STATISTICS_PER_USER_WITHOUT_ID
            }${order?.client?.id.toString()}
                    `}
          >
            {order?.client?.name}
          </Link>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {`${t('app.orderStatus')}: ${t(
            WAREHOUSE_B2B_STATUS_BY_STATUS[order?.warehouseStatus as string],
          )}`}
        </ListItem>
        {order?.externalOrdeId && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.externalOrdeId')}: ${order?.externalOrdeId}`}
          </ListItem>
        )}

        <Grid
          container
          spacing={0}
          alignItems='stretch'
          style={{ marginBottom: '24px' }}
        >
          <Grid item sm={6} xs={12}>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <img
                  src={iconWarehouse}
                  alt='barcode'
                  style={{ width: 35, height: 35 }}
                />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>{t('app.warehouse')}</Typography>
                <Box>{order?.warehouseName}</Box>
              </Box>
            </ListItem>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <PlaceIcon style={{ width: 35, height: 35 }} />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>
                  {t('app.b2bShipmentsPoint')}
                </Typography>
                <Box>{order?.shipmentPointTitle}</Box>
              </Box>
            </ListItem>
          </Grid>

          <Grid item sm={6} xs={12}>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <TodayIcon style={{ width: 35, height: 35 }} />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>{t('app.shipmentDate')}</Typography>
                <Box>{`${formattedSceduledDate} ${formattedSceduledTime}`}</Box>
              </Box>
            </ListItem>
            <ListItem className={classes.itemInfo}>
              <BoxCentered mr={2}>
                <LocalShippingIcon style={{ width: 35, height: 35 }} />
              </BoxCentered>
              <Box>
                <Typography variant='h3'>{t('app.shipmentMethod')}</Typography>
                <Box>{`${t(
                  B2B_SHIPMENT_METHOD_BY_METHOD[
                    order?.shipmentInfo?.typeMethod as string
                  ],
                )}`}</Box>
                {order?.shipmentInfo?.typeMethod ===
                  B2BShipmentMethodTypeEnum.PicknPack && (
                  <>
                    {order?.shipmentInfo?.driverInfo?.fullName &&
                      order?.shipmentInfo?.driverInfo?.mobileNumber && (
                        <Box>{`${order?.shipmentInfo?.driverInfo?.fullName} ${order?.shipmentInfo?.driverInfo?.mobileNumber}`}</Box>
                      )}
                    {order?.shipmentInfo?.driverInfo?.carModel &&
                      order?.shipmentInfo?.driverInfo?.carNumber && (
                        <Box>{`${order?.shipmentInfo?.driverInfo?.carModel} ${order?.shipmentInfo?.driverInfo?.carNumber}`}</Box>
                      )}
                  </>
                )}
              </Box>
            </ListItem>
          </Grid>
        </Grid>
        <Box pb={1} pl={2} mt={4}>
          <Typography variant='h6'>{t('app.listOfItems')}</Typography>
        </Box>
        <Box style={{ marginBottom: '24px' }}>
          {rowsProducts.length ? (
            <TableList headCells={productsHeadCells} rows={rowsProducts} />
          ) : null}
        </Box>
        {order?.comment && (
          <ListItem className={classes.itemInfo}>
            {`${t('app.comment')}: ${order?.comment}`}
          </ListItem>
        )}
        {order?.technicalTaskFileUrl ? (
          <Link
            className={classes.actionLink}
            style={{ marginBottom: '24px' }}
            to={`/api/getAttachment/${order?.technicalTaskFileUrl}`}
            target='_blank'
          >
            <IconButton component='span' style={{ padding: 0 }}>
              <AttachFileIcon />
            </IconButton>
            {t('app.task')}
          </Link>
        ) : null}

        {rows.length ? (
          <>
            <Box pb={1} pl={2} mt={4}>
              <Typography variant='h6'>{t('app.cargoplaces')}</Typography>
            </Box>
            <TableList headCells={cargoPlacesHeadCells} rows={rows} />
            <Box mt={2} ml={2} mr={2} display={'flex'} alignItems={'center'}>
              <Typography>{t('app.pass')}</Typography>
              <Link
                className={classes.actionLink}
                to={`/api/getAttachment/${
                  order?.transportFileUrl || order?.shipmentLabelFileUrl
                }`}
                target='_blank'
              >
                {order?.shipmentLabelFileUrl?.slice(0, -4) ||
                  order?.transportFileUrl?.slice(0, -4)}
              </Link>
            </Box>
          </>
        ) : null}

        {!!totalCost ? (
          <>
            <Box pb={1} pl={2} mt={4}>
              <Typography variant='h6'>{t('app.cost')}</Typography>
            </Box>
            {order?.servicesWithCost?.map((service) => {
              const totalItemCost =
                Number(service?.costInUSD) * Number(service?.qty);
              return totalItemCost ? (
                <ListItem className={classes.itemInfo}>
                  {`${service?.title}: ${totalItemCost.toFixed(2)} (${
                    service?.qty
                  })`}
                </ListItem>
              ) : null;
            })}
            <ListItem
              className={classes.itemInfo}
              style={{ fontWeight: 'bold' }}
            >
              {t('app.overall')} {totalCost.toFixed(2)}
            </ListItem>
          </>
        ) : null}
        <div className={classes.wrapButtons}>
          <Tooltip title={t('app.goToAllOrdersList')}>
            <Button
              fullWidth={isMediaQueryXs}
              variant='contained'
              startIcon={<ArrowBackIcon />}
              onClick={() => history.push(TRoutes.B2B_ORDERS)}
            >
              {t('app.back')}
            </Button>
          </Tooltip>
          <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
          <Tooltip title={t('app.toComplete')}>
            <Button
              type={'submit'}
              variant='contained'
              fullWidth={isMediaQueryXs}
              disabled={
                order?.warehouseStatus !== WarehouseB2BStatus.ShipmentReady ||
                completeB2BOrderMutationLoading
              }
              startIcon={<SaveIcon />}
              onClick={handleComplete}
            >
              {t('app.toComplete')}
            </Button>
          </Tooltip>
          <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
          <Tooltip title={t('app.toComplete')}>
            <Button
              variant='contained'
              fullWidth={isMediaQueryXs}
              disabled={
                !(
                  order?.warehouseStatus === WarehouseB2BStatus.Created ||
                  order?.warehouseStatus === WarehouseB2BStatus.Picked ||
                  order?.warehouseStatus === WarehouseB2BStatus.Processing
                )
              }
              startIcon={<LocalShippingIcon />}
              onClick={() =>
                history.push(
                  `${
                    TRoutes.B2B_ORDER_PACKAGING_WITHOUT_ID
                  }${order?.id?.toString()}`,
                )
              }
            >
              {t('app.goToPackaging')}
            </Button>
          </Tooltip>
          <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
          <Tooltip title={t('app.cancelOrderMessage')}>
            <Button
              fullWidth={isMediaQueryXs}
              variant='contained'
              startIcon={<CancelIcon style={{ color: COLORS.RED }} />}
              disabled={
                !isSuperAdmin ||
                order?.warehouseStatus === WarehouseB2BStatus.Created
              }
              onClick={() => {
                setIsOpenConfirmCancel(true);
              }}
            >
              {t('app.cancel')}
            </Button>
          </Tooltip>
        </div>
        <CancelWarehouseShipmentOrderModal
          orderIdToCancel={Number(id)}
          isOpenConfirmCancel={isOpenConfirmCancel}
          setIsOpenConfirmCancel={setIsOpenConfirmCancel}
          cancelWarehouseShipmentOrderHandler={cancelShipmentOrderHandler}
          cancelWarehouseShipmentOrderLoading={cancelB2BOrderMutationLoading}
        />
        <B2bShipmentViewCargoPlaceModal
          isOpenModal={isOpenViewCargoPlaceModal}
          setIsOpenModal={setisOpenViewCargoPlaceModal}
          cargoPlace={cargoPlace}
          cargoPlaceNumber={cargoPlaceNumber}
          rowsProducts={rowsCargoProducts}
        />
      </>
    </RequestHandler>
  );
};

export default B2bShipmentDetails;
